<template>
  <div class="overlay modal-box-clients" v-if="showFillClientDataModal" @click="closeModal">
    <div class="modal-box-clients-fill-data" @click.stop>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" fill="white"/>
          <path d="M28 12L12 28" stroke="black" stroke-width="2"/>
          <path d="M12 12L28 28" stroke="black" stroke-width="2"/>
        </svg>
      </button>
      <div class="container m-0 p-0">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group row">
              <label v-if="productType != 'tourism' && productType != 'greencard'" class="col-lg-6 col-md-6 col-sm-6 col-form-label d-none d-sm-block col-sm-fixed">1. Введіть ПІБ або ІПН клієнта</label>
              <label v-else class="col-lg-6 col-md-6 col-sm-6 col-form-label d-none d-sm-block col-sm-fixed">1. Введіть ІПН або ПІБ(Латиницею) клієнта</label>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <multiselect v-loading="pibInnLoading" :class="['form-control','custom-search-icon', 'custom-multiselect-labels']"
                             v-model="selectPibInn"
                             :options="pibInnOptions"
                             placeholder="ПІБ або ІПН клієнта"
                             @search-change="searchPibInn"
                             @select="saveSelectedPibInn"
                             select-label="Натисніть Enter, щоб вибрати"
                             deselect-label="Натисніть Enter, щоб очистити"
                             selected-label="Обрано"
                             label="fio"
                             track-by="id"
                             :allow-empty="true"
                             :internal-search="false"
                             :searchable="true"
                             :close-on-select="true"
                             :custom-label="customPibLabel"
                >
                  <template  v-if="productType != 'tourism' && productType != 'greencard'"  v-slot:noOptions>
                    Почніть вводити ПІБ або ІПН клієнта
                  </template>
                  <template  v-else  v-slot:noOptions>
                    Почніть вводити ПІБ(Латиницею) або ІПН клієнта
                  </template>
                  <template v-if="!pibInnLoading" slot="noResult">
                    Результатів немає
                  </template>
                  <template v-if="pibInnLoading" slot="noResult">
                    Зачекайте будь ласка...
                  </template>
                </multiselect>
              </div>
            </div>
            <div v-if="productType != 'tourism'" class="form-group row">
              <label :class="['col-lg-6','col-md-6','col-sm-6','col-form-label','d-none','d-sm-block','col-sm-fixed',{disabled:!selectPibInn || !selectVehicle}]">2. Оберіть авто клієнта зі списку</label>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <multiselect :disabled="!selectPibInn" :class="['form-control','custom-multiselect-labels']"
                             v-model="selectVehicle"
                             :options="vehicleOptions"
                             placeholder="Оберіть авто клієнта"
                             :max-height="165"
                             :options-limit="300"
                             :allow-empty="true"
                             :close-on-select="true"
                             select-label="Натисніть Enter, щоб вибрати"
                             deselect-label="Натисніть Enter, щоб очистити"
                             selected-label="Обрано"
                             label="registration_number"
                             track-by="id"
                             :searchable="true"
                             :internal-search="false"
                             :custom-label="customVehicleLabel"
                             @search-change="customFilter"
                >
                  <template slot="noOptions">
                    У цього клієнта ще немає збережених авто
                  </template>
                  <template slot="noResult">
                    Результатів немає
                  </template>
                </multiselect>
              </div>
            </div>
            <div v-else class="form-group custom-input row">
              <label :class="['col-lg-6','col-md-6','col-sm-6','col-form-label','d-none','d-sm-block','col-sm-fixed',{disabled:!selectPibInn || !selectTourists}]">2. Оберіть об’єкти страхування клієнта зі списку</label>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <multiselect :disabled="!selectPibInn" :class="['form-control','custom-multiselect-labels']"
                             v-model="selectTourists"
                             :options="touristsOptions"
                             placeholder="Оберіть застрахованих"
                             :max-height="265"
                             :options-limit="300"
                              open-direction="bottom"
                             :clear-on-select="false"
                             :limit="selectTouristsPersonLimit"
                             :limit-text="limitText"
                             :allow-empty="true"
                             :multiple="true"
                             :internal-search="false"
                             :close-on-select="false"
                             select-label="Натисніть Enter, щоб вибрати"
                             deselect-label="Натисніть Enter, щоб очистити"
                             selected-label="Обрано"
                             label="last_name"
                             track-by="id"

                >


                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="custom__tag"><span>{{ setCapitalizeFirstLetter(option.last_name) }} {{ getCapitalizedFirstLetter(option.first_name) }}</span><span class="custom__remove" @click="remove(option)">❌</span></span>
                  </template>
                  <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="touristsOptions.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <span>
                    <p>{{option.last_name}} {{option.first_name}} {{option.identification_number}} {{ showDateFormat(option.date_of_birth)}}</p>
<!--                    <p><b>{{option.document_name}}</b> {{option.passport_serial}} {{option.passport_number}} {{option.passport_date}}</p>-->
                    </span>
                  </template>
                  <template slot="noOptions">
                    У цього клієнта ще немає об’єктів страхування
                  </template>
                </multiselect>
              </div>
            </div>
            <div v-if="getcustomerType() != 2" class="form-group row">
              <label :class="['col-lg-6','col-md-6','col-sm-6','col-form-label','d-none','d-sm-block','col-sm-fixed',{disabled:!selectPibInn || !selectDocument}]">3. Оберіть необхідний документ</label>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <multiselect :disabled="!selectPibInn" :class="['form-control','custom-multiselect-labels']"
                             v-model="selectDocument"
                             :options="documentOptions"
                             placeholder="Оберіть документ"
                             :max-height="165"
                             :options-limit="300"
                             :allow-empty="true"
                             :internal-search="false"
                             :close-on-select="true"
                             select-label="Натисніть Enter, щоб вибрати"
                             deselect-label="Натисніть Enter, щоб очистити"
                             selected-label="Обрано"
                             label="registration_number"
                             track-by="id"
                             :custom-label="customDocumentLabel"
                >
                  <template slot="noOptions">
                    У цього клієнта немає потрібних документів
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col"></div>
              <div class="col-auto">
                <div class="button-container">
                  <button :disabled="!selectPibInn" :class="[{disabled:!selectPibInn}]" @click="save">Підтвердити</button>
                </div>
              </div>
              <div class="col"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {email, minLength, required} from "vuelidate/lib/validators";
import {getData} from '@/api/client';
import Multiselect from "vue-multiselect";
import {object} from "underscore";
import { list as getCompinatePlate } from '../../../api/getCompinatePlate';
export default {
  props: ['showFillClientDataModal', 'productType', 'offerInfo', 'searchInfo', 'clienData'],
  components: {Multiselect, getData, getCompinatePlate},
  data() {
    return {
      selectPibInn:false,
      selectVehicle:false,
      selectTourists:false,
      selectDocument:false,
      pibInnLoading:false,
      fetchClientsTimeout:false,
      selectTouristsPersonLimit:2,
      pibInnOptions: [],
      vehicleOptions: [],
      allVehicleOptions: [],
      touristsOptions: [],
      documentOptions: [],
    };
  },
  created(){
    window.addEventListener("resize", this.checkWindowResize);
    if(this.clienData && this.clienData.length > 0){
      this.selectPibInn = this.clienData;

      if(this.clienData.documents.length == 1){
        this.selectDocument = this.clienData.documents[0];
      }else{
        this.selectDocument = null;
        this.documentOptions = this.clienData.documents;
      }

      if(this.clienData.vehicles.length == 1){
        this.selectVehicle = this.clienData.vehicles[0];
      }else{
        this.selectVehicle = null;
        this.vehicleOptions = this.clienData.vehicles;
      }

      if(this.clienData.insuredPeople.length == 1){
        this.touristsOptions = this.clienData.insuredPeople;
        this.selectTourists = this.clienData.insuredPeople;
      }else{
        this.touristsOptions = this.clienData.insuredPeople;
      }
    }
  },
  computed:{
    availableDocuments() {
      return this.offerInfo?.available_documents ?? this.offerInfo?.allowed_documents ?? null;
    },
    commonDocumentTypes () {
      return this.$store.getters.COMMON_DOCUMENTS_TYPES
    },
    allDocumentTypes () {
      return this.$store.getters.DOCUMENTS_TYPES
    },
    customerPrivilege() {
      return this.offerInfo?.customerPrivilege ?? this.searchInfo.customerPrivilege ?? null;
    },
  },
  watch:{
    showFillClientDataModal: function (newVal,oldVal){
      if(newVal != oldVal){
        this.selectPibInn = false;
        this.selectVehicle = false;
        this.selectTourists = false;
        this.selectDocument = false;
        this.pibInnOptions = [];
        this.vehicleOptions = [];
        this.allVehicleOptions = [];
        this.touristsOptions = [];
        this.documentOptions = [];

        if(this.clienData){
          this.selectPibInn = this.clienData;
          this.pibInnOptions = [this.clienData];
          if(this.clienData.documents && this.clienData.documents.length == 1){
            this.documentOptions = this.clienData.documents;
            this.selectDocument = this.clienData.documents[0];
          }else{
            this.documentOptions = this.clienData.documents;
          }

          if(this.clienData.vehicles && this.clienData.vehicles.length == 1){
            this.vehicleOptions = this.clienData.vehicles;
            this.selectVehicle = this.clienData.vehicles[0];
          }else{
            this.vehicleOptions = this.clienData.vehicles;
          }

          if(this.clienData.insuredPeople && this.clienData.insuredPeople.length == 1){
            this.touristsOptions = this.clienData.insuredPeople;
            this.selectTourists = this.clienData.insuredPeople;
          }else{
            this.touristsOptions = this.clienData.insuredPeople;
          }

        }

      }
    },
    "window.innerWidth": function(newVal,oldVal) {
    },
    "selectPibInn":function (newVal,oldVal){
      this.getAllowedDocumentsTypes();
    }
  },
  methods: {
    getcustomerType() {
      if (this.searchInfo?.customerType) {
        return this.searchInfo?.customerType;
      } else if (this.searchInfo?.userType) {
        return this.searchInfo?.userType.id;
      }
      return false;
    },
    checkIsMobile: function() {
      //return this.condition.isMobile = window.innerWidth < this.mobileWith;
      if(window.innerWidth <= 438){
        this.selectTouristsPersonLimit = 1;
      }
      else if(window.innerWidth <= 575){
        this.selectTouristsPersonLimit = 2;
      }
      else if(window.innerWidth <= 689){
        this.selectTouristsPersonLimit = 1;
      }else{
        this.selectTouristsPersonLimit = 2;
      }
    },
    checkWindowResize: function() {
      this.checkIsMobile()
    },
    limitText (count) {
      if (count % 100 >= 11 && count % 100 <= 20) {
        return `+ ${count} клієнтів`;
      } else if (count % 10 === 1) {
        return `+ ${count} клієнт`;
      } else if (count % 10 >= 2 && count % 10 <= 4) {
        return `+ ${count} клієнта`;
      } else {
        return `+ ${count} клієнтів`;
      }
    },
    clearAll () {
      this.touristsOptions = []
    },
    searchPibInn(query) {
      if(query.length > 1){
        this.fetchClients(query);
      }
    },
    getCapitalizedFirstLetter(str) {
      if (str.length === 0) {
        return str;
      }
      return str.charAt(0).toUpperCase() + ".";
    },
    setCapitalizeFirstLetter(str) {
      if (str.length === 0) {
        return str;
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    saveSelectedPibInn(option, id) {
      this.selectVehicle = false;
      this.selectDocument = false;
      this.selectTourists = false;
      this.vehicleOptions = option.vehicles;
      this.allVehicleOptions = option.vehicles;
      this.touristsOptions = option.insuredPeople;
      this.documentOptions = option.documents;
    },
    async fetchClients(query) {
      if (!query) return;
      clearTimeout(this.fetchClientsTimeout);
      this.pibInnLoading = true;
      let params = {
        "requestType": "clientsByUserId",
        "seatchText": query,
        "customerType":this.getcustomerType()
      };
      this.fetchClientsTimeout = setTimeout(async () => {
        let response = await getData(params);
        this.pibInnOptions = response.data.data.items;
        this.pibInnLoading = false;
      }, 1000);
    },
    save() {
      this.submitNote = true;
      let params = {
        "selectPibInn" : this.selectPibInn,
        "selectVehicle" : this.selectVehicle,
        "selectDocument" : this.selectDocument,
        "selectTourists" : this.selectTourists,
      }

      this.$emit('save', params );
    },
    closeModal() {
      this.$emit('close');
    },
    // confirmDelete() {
    //   if (confirm('Вы уверены, что хотите удалить эту заметку?')) {
    //     this.$emit('delete', this.noteToEdit);
    //   }
    // },
    customPibLabel({fio, fio_en, identification_number, date_of_birth}){
      const parts = [];
      switch(this.productType){
        case "osago":
          if (fio && fio.trim()) parts.push(fio);
          if (identification_number && identification_number.trim()) parts.push(identification_number);
          if (date_of_birth && date_of_birth.trim()) parts.push(date_of_birth);
          break;
        case "greencard_insurance":
        case "greenCard":
          if (fio_en && fio_en.trim()) parts.push(fio_en);
          if (fio && fio.trim()) parts.push("("+fio+ ")");
          if (identification_number && identification_number.trim()) parts.push(identification_number);
          if (date_of_birth && date_of_birth.trim()) parts.push(date_of_birth);
          break;
        case "tourism":
          if (fio_en && fio_en.trim()) parts.push(fio_en);
          if (identification_number && identification_number.trim()) parts.push(identification_number);
          if (date_of_birth && date_of_birth.trim()) parts.push(date_of_birth);
          break;
        default:
          break;
      }
      return parts.join(', ');
    },
    customFilter(searchText) {
      getCompinatePlate(this.allVehicleOptions).then(({ data }) => {
        let allVehicleOptions = data.data.items;

        this.vehicleOptions = allVehicleOptions.filter((e) => {
          const parts = [];
          if (e.mark_name && e.mark_name.trim()) parts.push(e.mark_name);
          if (e.model_name && e.model_name.trim()) parts.push(e.model_name);
          if (e.year && e.year) parts.push(e.year);
          if (e.vin && e.vin.trim()) parts.push(e.vin);
          parts.push(...e.plates); // Добавляем все комбинации номеров в поиск
          return parts.join(' ').toLowerCase().includes(searchText.toLowerCase());
        });
      }).catch(err => {
        console.log(err);
        // Обработка ошибки
      });
    },
    // defaultFilter(option, searchText) {
    //   return option.mark_name.includes(searchText) ||
    //       option.model_name.includes(searchText) ||
    //       option.registration_number.includes(searchText) ||
    //       option.year.includes(searchText) ||
    //       option.vin.includes(searchText);
    // },
    customVehicleLabel({mark_name, model_name, year, registration_number, vin}){
      const parts = [];
      if (mark_name && mark_name.trim()) parts.push(mark_name);
      if (model_name && model_name.trim()) parts.push(model_name);
      if (registration_number && registration_number.trim()) parts.push(registration_number);
      if (year && year) parts.push(year);
      if (vin && vin.trim()) parts.push(vin);

      return parts.join(', ');
    },
    customDocumentLabel({document_name, serial, number, date_of_issue, issued_by}){
      const parts = [];

      if (document_name && document_name.trim()) parts.push(document_name);
      if (serial && serial.trim()) parts.push(serial);
      if (number && number.trim()) parts.push(number);
      if (date_of_issue && date_of_issue.trim()) parts.push(moment(date_of_issue, 'YYYY-MM-DD').format('DD-MM-YYYY'));
      if (issued_by && issued_by.trim()) parts.push(issued_by);

      return parts.join(', ');
    },
    showDateFormat(date){
      return (date) ? moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') : ""
    },

    getAllowedDocumentsTypes() {
        if (this.selectPibInn.client_type !== "3"/*Не пільговик*/) {
            let transformedDocs = this.availableDocuments.map(docId => {
                let matchingItem = this.allDocumentTypes.find(item => item.id === docId);
                if(!matchingItem) return false;
                return {
                    id: matchingItem.id,
                    name: matchingItem.name,
                    uid: matchingItem.uid,
                };
            });

            const validUids = transformedDocs.map(doc => doc.uid);
            this.documentOptions = this.documentOptions.filter(doc => validUids.includes(parseInt(doc.document_type)));
            // this.documentOptions = filteredDocumentOptions;
        } else {
            this.documentOptions = this.documentOptions.filter(doc => {
                const matchingItem = this.$store.getters.CUSTOMER_PRIVILEGE.find(item => item.id === parseInt(this.customerPrivilege));

                return matchingItem.documentUid === doc.document_type;
            });
            // this.documentOptions = filteredDocumentOptions;
        }
    },
  }
}
</script>


<style scoped>
.overlay.modal-box-clients {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.modal-box-clients .modal-box-clients-fill-data .form-group{
  margin: 0px;
}
.modal-box-clients .modal-box-clients-fill-data {
  background-color: white;
  padding: 20px;
  border-radius: 3px;
  width: 880px;
  position: relative;
  padding-top: 41px;
}

.modal-box-clients .modal-box-clients-fill-data textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.modal-box-clients .modal-box-clients-fill-data button {
  border-radius: 3px;
  color: #FFFFFF;
  background-color: #FFAD3D;
  border: 0;
  font-size: 1rem;
  cursor: pointer;
  font-weight: var(--font-weight-bold);
  outline: none;
  min-height: 50px;
  padding: 0 15px 0 15px;
  margin-top: 15px;

}
.modal-box-clients .modal-box-clients-fill-data button.disabled {
  background-color: #CACACA;
}


.modal-box-clients .modal-box-clients-fill-data .col-form-label {
 color: #24282C;
  font-weight: 400;
  margin-bottom: 15px;
}
.modal-box-clients .modal-box-clients-fill-data label.disabled {
 color: #999DA6;
}

.modal-box-clients .modal-box-clients-fill-data button:hover {
  opacity: 0.8;
}
.modal-box-clients .modal-box-clients-fill-data button:last-child {
  margin-right: 0;
}

.modal-box-clients .modal-box-clients-fill-data button:focus {
  outline: none;
}
.modal-box-clients .modal-box-clients-fill-data .close{
  position: absolute;
  right: 4px;
  top: 0px;
  background-color: transparent!important;
  margin: 0!important;
  padding: 0!important;
}
@media screen and (max-width: 410px){
  .modal-box-clients .modal-box-clients-fill-data{
    width: 92%;
  }
}
@media screen and (max-width: 924px){
  .modal-box-clients .modal-box-clients-fill-data{
    margin: 0 15px;
  }
}
</style>
<style>
.modal-box-clients .modal-box-clients-fill-data .form-group .form-control.multiselect.custom-search-icon .multiselect__select:before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.2939 12.5786H13.3905L13.0703 12.2699C14.191 10.9663 14.8656 9.27387 14.8656 7.43282C14.8656 3.32762 11.538 0 7.43282 0C3.32762 0 0 3.32762 0 7.43282C0 11.538 3.32762 14.8656 7.43282 14.8656C9.27387 14.8656 10.9663 14.191 12.2699 13.0703L12.5786 13.3905V14.2939L18.2962 20L20 18.2962L14.2939 12.5786ZM7.43282 12.5786C4.58548 12.5786 2.28702 10.2802 2.28702 7.43282C2.28702 4.58548 4.58548 2.28702 7.43282 2.28702C10.2802 2.28702 12.5786 4.58548 12.5786 7.43282C12.5786 10.2802 10.2802 12.5786 7.43282 12.5786Z' fill='%23999DA6'/%3E%3C/svg%3E");
  display: block;
  width: 20px;
  height: 20px;
  top: 0px;
  left: 0px;
  transition: 0.3s;
  transform: rotate(90deg);

}
.modal-box-clients .modal-box-clients-fill-data .form-group .form-control.multiselect.custom-search-icon.multiselect--active .multiselect__select:before{
  transform: rotate(-90deg);
  width: 20px;
  height: 20px;
  top: 0px;
  left: 0px;
  right: 0px;
  transition: 0.3s;
}

.modal-box-clients .modal-box-clients-fill-data .custom__tag{
  padding: 4px 6px 4px 4px;
  background: #eaeaea;
  margin-right: 5px;

}
.modal-box-clients .modal-box-clients-fill-data .multiselect__content{
  width: 100%;
}
.modal-box-clients .modal-box-clients-fill-data .multiselect__content .multiselect__option span{
  text-wrap: initial;
  line-height: 19px;
}
.modal-box-clients .modal-box-clients .modal-box-clients-fill-data .custom__remove{
  cursor: pointer;
}
.modal-box-clients .modal-box-clients-fill-data .form-group .form-control.multiselect .multiselect__tags-wrap{
  white-space:initial;
}
.modal-box-clients .modal-box-clients-fill-data .form-group.custom-input .form-control.multiselect .multiselect__input{
  width: 0px !important;
}


@media screen and (max-width: 368px){
  .modal-box-clients .modal-box-clients-fill-data .form-group .form-control.multiselect .multiselect__tags .multiselect__strong{
    display: none;
  }
}

</style>
